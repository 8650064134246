import './App.css';
import TradingChart from './TradingChart';

function App() {
    return (
        <div>
            {
                <div style={{width: "100%"}}>
                    <TradingChart style={{width: "100%"}}/>
                </div>
            }
        </div>
    );
}

export default App;
