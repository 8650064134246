// TradingChart.js
import React, { useEffect, useRef, useState } from 'react';
import { ColorType, createChart, LineStyle } from 'lightweight-charts';
import io from 'socket.io-client';

const TradingChart = () => {
    const chartContainerRef = useRef(null);
    const chartContainerRefHisto = useRef(null);
    const chartContainerRefHisto2 = useRef(null);

    const candlestickSeriesRef = useRef(null);
    const histoSeriesRef = useRef(null);
    const histoSeriesRef2 = useRef(null);
    const [tooltip, setTooltip] = useState(null);

    useEffect(() => {
        // Create the chart
        const chart1 = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: window.screen.height*0.6,
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time) => {
                    const date = new Date(time);
                    const minutes = date.getMinutes();
                    const hours = date.getHours();
                    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                },
                ticksVisible: true,
            },
        });

        // Add candlestick series
        candlestickSeriesRef.current = chart1.addCandlestickSeries({
            upColor: '#f4f802',
            downColor: '#e42e07',
            borderDownColor: '#e42e07',
            borderUpColor: '#f4f802',
            wickDownColor: '#e42e07',
            wickUpColor: '#f4f802',
        });

        // Create the chart Histo
        const chart2 = createChart(chartContainerRefHisto.current, {
            width: chartContainerRefHisto.current.clientWidth,
            height: window.screen.height*0.15,
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time) => {
                    const date = new Date(time);
                    const minutes = date.getMinutes();
                    const hours = date.getHours();
                    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                },
                ticksVisible: true,
            },
        });

        // Add histogram series
        histoSeriesRef.current = chart2.addHistogramSeries({
            upColor: '#f4f802',
            downColor: '#e42e07',
            borderDownColor: '#e42e07',
            borderUpColor: '#f4f802',
            wickDownColor: '#e42e07',
            wickUpColor: '#f4f802',
        });

        // Create the chart Histo2
        const chart3 = createChart(chartContainerRefHisto2.current, {
            width: chartContainerRefHisto2.current.clientWidth,
            height: window.screen.height*0.15,
            title:"BULISHNESS",
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time) => {
                    const date = new Date(time);
                    const minutes = date.getMinutes();
                    const hours = date.getHours();
                    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                },
                ticksVisible: true,
            },
        });

        // Add histogram series2
        histoSeriesRef2.current = chart3.addHistogramSeries({
            upColor: '#f4f802',
            downColor: '#e42e07',
            borderDownColor: '#e42e07',
            borderUpColor: '#f4f802',
            wickDownColor: '#e42e07',
            wickUpColor: '#f4f802',
        });

        // Fetch initial data
        const fetchData = async () => {
            try {
                const response = await fetch("https://socket.crazii.online/get-chart-data");
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                const data = result.data.reverse().map(d => ({
                    time: Date.parse(d.timestamp.replace(/\./g, '-') + ":00"),
                    open: d.open,
                    high: d.high,
                    low: d.low,
                    close: d.close,
                    value:d.ksi_red<100?d.ksi_red:d.ksi_green,
                    color:d.ksi_red<100?"red":"green",
                    kcx:d.kcx
                }));

                console.log('Before:', data);
                // Sort and remove duplicates to avoid error :  Assertion failed: data must be asc ordered by time, index=15, time=1725384300000, prev time=1725384300000
                const sortedData = data.sort((a, b) => a.time - b.time).filter((item, index, arr) => index === 0 || item.time !== arr[index - 1].time);
                console.log('After:', sortedData);
                const kcx = sortedData.map(d => ({
                    time: d.time,
                    color:"blue",
                    value:d.kcx
                }));
                candlestickSeriesRef.current.setData(sortedData);
                histoSeriesRef.current.setData(sortedData);
                histoSeriesRef2.current.setData(kcx);

                // Function to make the upper half blink
                let isBlinking = true;
                const blinkUpperHalf = () => {
                    histoSeriesRef2.current.setData(
                    isBlinking
                        ? kcx.map((d) => ({ ...d, color: 'green' })) // Make it disappear
                        : kcx // Original data to make it reappear
                    );
                    isBlinking = !isBlinking;
                };
            
                // Start blinking every 500ms
                //const blinkInterval = setInterval(blinkUpperHalf, 500);

                let draw_data=result.data[result.data.length-1];
                // OP
                candlestickSeriesRef.current.createPriceLine({
                    price: result.data[result.data.length-1].op_line,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'OP ('+draw_data.op_line+')',
                });

                // MLP
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.mlp_line,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'MLP ('+draw_data.mlp_line+')',
                });

                // KTR + 1 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_1,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+1 ('+draw_data.ktr_plus_1+')',
                });

                // KTR + 2 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_2,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+2 ('+draw_data.ktr_plus_2+')',
                });

                // KTR + 3 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_3,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+3 ('+draw_data.ktr_plus_3+')',
                });

                // KTR - 1 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_minus_1,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-1 ('+draw_data.ktr_minus_1+')',
                });

                // KTR - 2 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_minus_2,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-2 ('+draw_data.ktr_minus_2+')',
                });

                // KTR - 3 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktf_minus_3,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-3 ('+draw_data.ktf_minus_3+')',
                });

                // Pivote-1 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.pivot_1,
                    color: draw_data.pivot_colour,
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'Pivot-1 ('+draw_data.pivot_1+')',
                });

                // Pivote-2 
                candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.pivot_2,
                    color: draw_data.pivot_colour,
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'Pivot-1 ('+draw_data.pivot_2+')',
                });

                // Synchronize chart1 with chart2 and chart3
                syncCharts(chart1, [chart2, chart3]);
                syncCharts(chart2, [chart1, chart3]);
                syncCharts(chart3, [chart1, chart2]);

            } catch (error) {
                console.error("Failed to fetch chart data:", error);
            }
        };

        fetchData();

        // Subscribe to crosshair movements
        chart1.subscribeCrosshairMove(param => {
            const price = param.time && param.seriesData.get(candlestickSeriesRef.current);
            if (price) {
                setTooltip({
                    time: param.time,
                    open: price.open,
                    high: price.high,
                    low: price.low,
                    close: price.close,
                });
            } else {
                setTooltip(null);
            }
        });

        // Resize the chart on window resize (debounced)
        let resizeTimeout;

       // Sync the time scales
        const syncCharts = (sourceChart, targetCharts) => {
            sourceChart.timeScale().subscribeVisibleTimeRangeChange((newTimeRange) => {
            targetCharts.forEach((targetChart) => {
                targetChart.timeScale().setVisibleRange(newTimeRange);
            });
            });
        };
    
      
    
        // Resize charts on window resize
        const handleResize = () => {
            const width = chartContainerRef.current.clientWidth;
            chart1.applyOptions({ width });
            chart2.applyOptions({ width });
            chart3.applyOptions({ width });
        };
    
        window.addEventListener('resize', handleResize);

        // Socket connection for real-time updates
        const socket = io('https://socket.crazii.online');
        let lastUpdate = 0;
        const updateInterval = 1000; // Update every second

        socket.on("chart", (chart) => {
            const now = Date.now();
            if (now - lastUpdate < updateInterval) return;

            lastUpdate = now;
            const time = Date.parse(chart[4].replace(/\./g, '-') + ":00");
            const open = parseFloat(chart[9]);
            const high = parseFloat(chart[10]);
            const low = parseFloat(chart[11]);
            const close = parseFloat(chart[12]);

            candlestickSeriesRef.current.update({
                time,
                open,
                high,
                low,
                close,
            });
        });

        // Clean up on component unmount
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener('resize', handleResize);
            if (chart1) {
                chart1.remove();
                chart2.remove();
                chart3.remove();
            }
            candlestickSeriesRef.current.current = null;
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <div ref={chartContainerRef} style={{ 
                width: '100%',
            }} />

            <div ref={chartContainerRefHisto} style={{ 
                width: '100%',
            }} />
            
            <div ref={chartContainerRefHisto2} style={{ 
                width: '100%',
            }} />

            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                {tooltip && (
                    <div style={{ backgroundColor: '#000', color: '#fff', padding: '5px', borderRadius: '5px', marginBottom: '10px' }}>
                        <div><strong>Time:</strong> {new Date(tooltip.time * 1000).toLocaleDateString()}</div>
                        <div><strong>Open:</strong> {tooltip.open}</div>
                        <div><strong>High:</strong> {tooltip.high}</div>
                        <div><strong>Low:</strong> {tooltip.low}</div>
                        <div><strong>Close:</strong> {tooltip.close}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TradingChart;
